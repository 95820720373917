<template>
  <div class="calculator">
    <div class="calculator__settings">
      <div class="settings-room">
        <div class="settings-room__width">
          <label>
            <span>Raumbreite (mm)</span>
            <input type="number" v-model.number="room.width">
          </label>
        </div>
        <div class="settings-room__height">
          <label>
            <span>Raumhöhe (mm)</span>
            <input type="number" v-model.number="room.height">
          </label>
        </div>
      </div>
      <div class="settings-tile">
        <div class="settings-tile__width">
          <label>
            <span>Materiallänge (cm)</span>
            <input type="number" v-model.number="tile.width">
          </label>
        </div>
        <div class="settings-tile__height">
          <label>
            <span>Materialhöhe (cm)</span>
            <input type="number" v-model.number="tile.height">
          </label>
        </div>
      </div>
      <div class="settings-math">
        <div class="settings-math__fugues">
          <label>
            <span>Fugen</span>
            <input type="number" v-model="fugues">
          </label>
        </div>
        <div class="settings-math__rows">
          <label>
            <span>Reihen</span>
            <input type="number" v-model="useRows">
          </label>
        </div>
      </div>
      <div>
        <em>
          Tipp: Für ein schönes Bild sollte die Anzahl der Fugen der Anzahl der Reihen entsprechen.
        </em>
      </div>
      <!--div class="pattern">{{pattern}}</div-->
    </div>
    <div class="output">
      <div class="output__info">
        Raumgröße: {{room.width / 1000 * room.height / 1000}} m<sup>2</sup>
      </div>
      <div class="output__info">
        Verschnitt
      </div>
    </div>
    <div
      class="calculator__canvas"
      :style="`width: ${room.width / 10}px; height: ${room.height / 10}px`"
      v-if="
        typeof room.width === 'number'
        && typeof room.height === 'number'
        && typeof requiredRows === 'number'
      "
    >
      <div
        class="tile-row"
        v-for="(row, index) in (requiredRows > 0) ? requiredRows : 0"
        :key="`tile-row-${index}`"
      >
        <div
          class="tile-row__tile tile"
          :style="`width: ${getInitialTileWidth(index)}px; height: ${tile.height - 2}px`"
        >
          <span>{{Math.round((getInitialTileWidth(index, true)) * 100) / 100}}</span>
        </div>
        <div
          class="tile-row__tile tile"
          :style="`width: ${tile.width - 4}px; height: ${tile.height - 2}px`"
          v-for="
            (tileInstance, tileInstanceIndex) in
            calculateFullTilesPerRow(getInitialTileWidth(index, true)) > 0
            ? calculateFullTilesPerRow(getInitialTileWidth(index, true)) : 0
          "
          :key="`tile-instance-${tileInstanceIndex}`"
        >
          <span>{{tile.width}}</span>
        </div>
        <div
          class="tile-row__tile tile"
          :style="`width: ${getFinalTileWidth(
            getInitialTileWidth(index, true),
            calculateFullTilesPerRow(getInitialTileWidth(index, true))
          )}px; height: ${tile.height - 2}px`"
        >
          <span>{{Math.round(
            getFinalTileWidth(
              getInitialTileWidth(index, true),
              calculateFullTilesPerRow(getInitialTileWidth(index, true)),
              true
            )
            * 100) / 100}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calculator',
  data() {
    return {
      room: {
        width: 4000,
        height: 4000,
      },
      tile: {
        width: 128.7,
        height: 23,
      },
      fugues: 4,
      useRows: 4,
      // rowMultiplicator: 0,
    };
  },
  methods: {
    getInitialTileWidth(index, formattedOutput) {
      if (index === 0 && !formattedOutput) {
        this.rowMultiplicator = 0;
      }

      if (this.rowMultiplicator >= this.useRows && !formattedOutput) {
        this.rowMultiplicator = 0;
      }

      if (!formattedOutput) {
        this.rowMultiplicator += 1;
      }

      const cssCorrection = formattedOutput ? 0 : 4;

      return (this.tile.width / this.fugues) * this.rowMultiplicator - cssCorrection;
    },
    getFinalTileWidth(initialTileWidth, fullTilesPerRow, formattedOutput) {
      const cssCorrection = formattedOutput ? 0 : 4;
      const roomWidth = this.room.width / 10;
      const fixTileWidth = fullTilesPerRow * this.tile.width;

      return roomWidth - fixTileWidth - initialTileWidth - cssCorrection;
    },
    calculateFullTilesPerRow(start) {
      const value = Math.floor((this.room.width / 10 - start) / this.tile.width);

      if (typeof value === 'number') {
        return value;
      }

      return 0;
    },
  },
  computed: {
    requiredTilesPerRow() {
      const required = Math.ceil((this.room.width / 10) / this.tile.width) - 2;

      if (!required || required < 0) {
        return 0;
      }

      return required;
    },
    requiredRows() {
      return Math.ceil((this.room.height / 10) / this.tile.height);
    },
    pattern() {
      const tileLength = this.tile.width / this.fugues;
      const rowConfig = [];

      for (let i = 0; i < this.fugues; i += 1) {
        if (typeof this.room.width === 'number') {
          rowConfig.push(this.room.width / 10 - 2 * this.tile.width - (tileLength * (i + 1)));
        }
      }

      return rowConfig;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calculator {
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  min-height: 100vh;
}

.calculator__settings,
.output {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.settings-room,
.settings-tile,
.settings-math {
  display: flex;
  margin-bottom: 20px;
}

label {
  display: flex;
  margin-right: 20px;
}

label > span {
  display: inline-block;
  width: 140px;
}

.calculator__canvas {
  margin: 0 20px;
  margin-top: 40px;
  background-color: gray;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tile-row {
  display: flex;
  overflow: hidden;
}

.tile {
  background-color: rgb(58, 58, 58);
  border-left: 2px solid rgb(23, 211, 218);
  border-right: 2px solid rgb(255, 76, 76);
  border-bottom: 2px solid gray;
}

.tile span {
  font-size: 10px;
  font-weight: bold;
  color: white;
  text-align: center;
  display: inline-block;
  width: 100%;
}
</style>
